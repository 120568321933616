import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import DashboardMap from '../components/Map/organisms/DashboardMap';
import DashboardEvents from '../components/dashboard-table/organisms/DashboardEvents';
import DashboardTabs from '../components/molecules/DashboardTabs';
import { useAlertCount } from '../hooks/useAlertCount';
import { useAlerts } from '../hooks/useAlerts';
import { Camera, useCameras } from '../hooks/useCameras';
import { useLocations, Location } from '../hooks/useLocations';
import { useZones, Zone } from '../hooks/useZones';
import { useStats} from '../hooks/useStats';
import { Tabs, usePoll } from 'scorer-ui-kit';
import { DASHBOARD_PARAMS, useSelected } from '../hooks/useSelected';
import { isBefore } from 'date-fns';
import VerticalDivider from '../components/atoms/VerticalDivider';
import HorizontalDivider from '../components/atoms/HorizontalDivider';
import { useLocalStorage } from '../hooks/useLocalStorage';


const GridContainer = styled.div<{columnWidth?: string;rowHeight?: string}>`
  display: grid;
  height: calc(100vh - 65px);
  flex: 1;
  width: 100%;
  // for now the sidebar and drawer have fixed pixel reservations, we can change in the future for resize-ability.
  ${({columnWidth='400px;'}) => css`grid-template-columns: 1fr 4px ${columnWidth};` }
  ${({rowHeight='300px;'}) => css`grid-template-rows: 1fr 4px ${rowHeight};` }
  grid-template-areas:
    "main-pane v-divider side-pane"
    "h-divider h-divider h-divider"
    "drawer-pane drawer-pane drawer-pane";
  isolation: isolate;
`;
const MainPane = styled.div`
  grid-area: main-pane;
  overflow: hidden;
`;

const SidePane = styled.div`
  grid-area: side-pane;
  overflow: hidden;
`;
const DrawerPane = styled.div`
  grid-area: drawer-pane;
  overflow: hidden;
  height: 100%;
`;

export interface RichLocation extends Location{
  zones: RichZone[];
  totalCameras: number;
  locationAlerts?: number;
}
export interface RichZone extends Zone {
  cameras: Camera[];
  zoneAlerts?: number;
  detectionTypes?: string;
}

const DashboardPage: React.FC<{}> = () => {

  const {cameras, actions: {fetchCameras}} = useCameras();
  const {alerts} = useAlerts();
  const {locations, actions: {fetchLocations}} = useLocations();
  const {alertCounts, actions:{fetchAlertCounts}} = useAlertCount();
  const {zones, actions:{fetchZones}} = useZones();
  const {stats, actions: {fetchStats}} = useStats();
  const [richLocations, setRichLocations] = useState<RichLocation[]>([]);
  const { getParam } = useSelected();

  const getStartDate = useCallback(() => {
    const date = new Date();
    const hrs = getParam(DASHBOARD_PARAMS.fromTimeHrs);
    const mins = getParam(DASHBOARD_PARAMS.fromTimeMins);
    date.setHours(Number(hrs) ?? 0, Number(mins) ?? 0, 0);
    if(!isBefore(date, new Date())) {
      date.setDate(date.getDate() - 1);
    }
    return date;
  }, [getParam]);

  const [sidePaneWidth, setSidePaneWidth] = useLocalStorage<number>('dashboard.side-pane.width',400);
  const [drawerPaneHeight, setDrawerPaneHeight] = useLocalStorage<number>('dashboard.side-pane.height',300);

  const [mapResize, setMapResize] = useState(false);

  const triggerResize = useCallback(()=>{
    setMapResize(resize => !resize);
  },[]);


  useEffect(()=>{
    if(cameras.length === 0) {
      fetchCameras();
    }
  },[cameras.length, fetchCameras]);

  useEffect(()=>{
    if(locations.length === 0) {
      fetchLocations();
    }
  },[fetchLocations, locations.length]);

  useEffect(()=>{
    if(zones.length === 0) {
      fetchZones();
    }
  },[fetchZones, zones.length]);

  // leave this under the useEffect above so stats poll starts after all base entities.
  usePoll(() => fetchStats({ startTime: getStartDate()}), 28000);


  useEffect(()=>{
    if (zones && cameras && locations && alertCounts) {
      const richLocations = locations.map(location => {
        let totalCameras = 0;
        let locationAlerts = 0;
        const richZones = zones.filter(({location_id})=>location_id === location.id).map((zone) => {
          const richCameras = cameras.filter(({zone_id}) => zone_id === zone.id);

          const { zoneAlerts, detectionTypes } = richCameras.reduce((prevResult, { id, detection_types }) => {
            prevResult.zoneAlerts += alertCounts[id] || 0;
            if (!prevResult.detectionTypes.includes(detection_types)) {
              prevResult.detectionTypes += prevResult.detectionTypes ?  ', ' + detection_types : detection_types;
            }
            return prevResult;
          }, {zoneAlerts: 0, detectionTypes: ''});

          totalCameras += richCameras.length;
          locationAlerts += zoneAlerts;
          return {
            ...zone,
            cameras: richCameras,
            zoneAlerts,
            detectionTypes
          }
        })
        return {
          ...location,
          zones: richZones,
          totalCameras,
          locationAlerts
        }
      });
      setRichLocations(richLocations);
    }
  },[zones,locations, cameras, alertCounts]);

  useEffect(()=>{
    //TODO: kinda lame should probably just calculate the new value
    fetchAlertCounts();
  },[fetchAlertCounts, alerts, getStartDate]);


  return (
    <GridContainer columnWidth={`${sidePaneWidth}px`} rowHeight={`${drawerPaneHeight}px`} >
      <MainPane>
        <DashboardMap {...{ locations, cameras, zones, stats, alertCounts, mapResize }}/>
      </MainPane>
      <VerticalDivider currentWidth={sidePaneWidth} setWidth={setSidePaneWidth} onDragEnd={triggerResize} />
      <SidePane>
        <Tabs>
          <DashboardTabs {...{ locations, cameras, zones, alertCounts, alerts}} richLocations={richLocations} />
        </Tabs>
      </SidePane>
      <HorizontalDivider currentHeight={drawerPaneHeight} setHeight={setDrawerPaneHeight} onDragEnd={triggerResize} />
      <DrawerPane>
        <DashboardEvents cameras={cameras} />
      </DrawerPane>
    </GridContainer>
  );
}

export default DashboardPage;
