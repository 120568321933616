import React, { FC, useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { lightTheme, darkTheme, themeFallbackHelper, ModalProvider, NotificationProvider } from 'scorer-ui-kit';
import { ThemeProvider } from 'styled-components';
import App from './App';
import Fonts from './Fonts';
import reportWebVitals from './reportWebVitals';
import Style from './Style';
import 'leaflet/dist/leaflet.css';


const darkThemeFallback = themeFallbackHelper(darkTheme, lightTheme);

const Contents: FC = () => {
  const [isDarkThemeEnabled, setIsDarkThemeEnabled] = useState(localStorage.getItem('isDarkThemeEnabled') !== 'false');

  const onChangeTheme = useCallback(() => {
    setIsDarkThemeEnabled((enabled) => {
      localStorage.isDarkThemeEnabled = !enabled;
      return !enabled;
    });
  }, []);

  return (
    <ThemeProvider theme={isDarkThemeEnabled ? darkThemeFallback : lightTheme}>
      <ModalProvider>
        <NotificationProvider>
          <App {...{ isDarkThemeEnabled, onChangeTheme }} />
          <Fonts />
          <Style />
        </NotificationProvider>
      </ModalProvider>
    </ThemeProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Contents />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
